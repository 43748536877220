import { useBreakpointsContext } from "@air/provider-media-query";
import layoutGeometry from "justified-layout";
import defaultsDeep from "lodash/defaultsDeep";
import { useMemo } from "react";
export const useJustifiedGrid = (param)=>{
    let { containerWidth = 100, ratios = [], options = {} } = param;
    const { isAboveSmallScreen } = useBreakpointsContext();
    const horizontalContainerPadding = 0;
    const verticalBoxSpacing = !isAboveSmallScreen ? 8 : 16;
    const horizontalBoxSpacing = !isAboveSmallScreen ? 8 : 16;
    const otherOptions = useMemo(()=>defaultsDeep(options, {
            boxSpacing: {
                horizontal: horizontalBoxSpacing,
                vertical: verticalBoxSpacing
            },
            containerPadding: {
                bottom: 0,
                right: horizontalContainerPadding,
                left: horizontalContainerPadding
            },
            targetRowHeight: 160,
            showWidows: true
        }), [
        horizontalBoxSpacing,
        horizontalContainerPadding,
        options,
        verticalBoxSpacing
    ]);
    const geometry = useMemo(()=>layoutGeometry(ratios, {
            containerWidth,
            ...otherOptions
        }), [
        containerWidth,
        otherOptions,
        ratios
    ]);
    const { boxes, containerHeight } = geometry;
    return {
        boxes: !!otherOptions.additionalRowHeight ? boxes.map((box)=>({
                ...box,
                height: box.height + otherOptions.additionalRowHeight
            })) : boxes,
        containerHeight
    };
};
