import { Auth } from '@air/api';
import { getCurrentSessionKey, getCurrentUserInfoKey } from '@air/utils-auth';
import { isObjectWithMessage } from '@air/utils-error';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { getAccountKey } from '~/swr-hooks/account/useAccount';
import { callOnAnonymousUserPool } from '~/utils/callOnAnonymousUserPool';
import { isCypressRun } from '~/utils/PathUtils';

export const useAnonymousSignUp = () => {
  const queryClient = useQueryClient();

  const refreshAccountCache = useCallback(
    () =>
      Promise.all([
        queryClient.invalidateQueries({ queryKey: getCurrentSessionKey() }),
        queryClient.invalidateQueries({ queryKey: getCurrentUserInfoKey() }),
        queryClient.invalidateQueries({ queryKey: getAccountKey() }),
      ]),
    [queryClient],
  );

  const loginWithEmail = useCallback(
    async ({ email, recaptchaToken }: { email: string; recaptchaToken: string }) =>
      callOnAnonymousUserPool(async () => {
        try {
          // We do not want to use recaptcha token in cypress tests - backend allows ignoring it on qa env
          const account = await Auth.anonymousAccountSignUp({ email, recaptchaToken, ignoreToken: isCypressRun() });
          await refreshAccountCache();
          return account;
        } catch (error) {
          // anonymous user with a given email does not exist - account must be created, and requires a name
          if (isObjectWithMessage(error) && error.message.includes('NAME_REQUIRED')) {
            return;
          } else {
            throw error;
          }
        }
      }),
    [refreshAccountCache],
  );

  const signupWithEmailAndName = useCallback(
    async ({ email, name, recaptchaToken }: { email: string; name: string; recaptchaToken: string }) =>
      callOnAnonymousUserPool(async () => {
        // We do not want to use recaptcha token in cypress tests - backend allows ignoring it on qa env
        const account = await Auth.anonymousAccountSignUp({ email, name, recaptchaToken, ignoreToken: isCypressRun() });
        if (!account) {
          throw new Error('Account was not created');
        }
        await refreshAccountCache();
        return account;
      }),
    [refreshAccountCache],
  );

  return {
    loginWithEmail,
    signupWithEmailAndName,
  };
};
