import { Clip } from '@air/api/types';
import { getAssetPreviewType, WebClipType } from '@air/utils-clips';
import { useMemo } from 'react';

type ClipRatioInfo = Pick<Clip, 'width' | 'height' | 'type' | 'ext'>;

const maxRatio = 3 / 1;
const minRatio = 3 / 4;

export const ClipRatios: { [key in WebClipType]: number } = {
  pdf: 3 / 4,
  document: 3 / 4,
  photo: 1,
  video: 16 / 9,
  file: 4 / 1,
};

export const getClipRatio = ({ height, width, type, ext }: ClipRatioInfo) => {
  const webType = getAssetPreviewType({ type, ext });
  // certain old legacy clips don't have widths and heights so we modify it here
  if (!width || !height) {
    return webType ? ClipRatios[webType] : 1;
  }
  const ratio = width / height;
  if (ratio >= 1) {
    return Math.min(maxRatio, ratio);
  } else {
    return Math.max(minRatio, ratio);
  }
};

export default function useClipRatios(clips: ClipRatioInfo[]): number[] {
  return useMemo(() => clips.map((clip) => getClipRatio(clip)), [clips]);
}
