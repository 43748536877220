import { itemIsSelectedSelector, selectedItemsCountSelector } from "@air/redux-selected-items";
import { useAirSelector, useAirStore } from "~/utils/ReduxUtils";
export function useSelectionAwareContextMenuOptions(param) {
    let { id, contextMenuOptions, selectionMenuOptions } = param;
    const store = useAirStore();
    const isSelected = useAirSelector((st)=>itemIsSelectedSelector(st, id));
    const selectedCount = selectedItemsCountSelector(store.getState());
    return {
        options: selectedCount > 1 && isSelected ? selectionMenuOptions : contextMenuOptions
    };
}
