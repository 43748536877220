import { hasSelectedItemsSelector, resetSelectedItemsAction, setShowShiftSelectHighlightsAction, showShiftSelectHighlightsSelector } from "@air/redux-selected-items";
import { useEffect } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useDispatch, useSelector } from "react-redux";
import useIsShiftPressed from "~/hooks/useIsShiftPressed";
import usePrevious from "~/hooks/usePrevious";
import { getClipIdFromPath } from "~/utils/PathUtils";
/**
 * This component enables hotkeys for select feature:
 * - shift for multiselect
 * - esc to clear selection
 */ export const MultiselectKeys = ()=>{
    const dispatch = useDispatch();
    const holdingShift = useIsShiftPressed();
    const hasSelectedItems = useSelector(hasSelectedItemsSelector);
    const isShowingMultiselectHighlight = useSelector(showShiftSelectHighlightsSelector);
    const previousIsShowingMultiselectHighlight = usePrevious(isShowingMultiselectHighlight);
    // this effect determines whether to turn on showing selected clip previews
    useEffect(()=>{
        const showShiftSelectHighlights = holdingShift && hasSelectedItems;
        if (showShiftSelectHighlights !== previousIsShowingMultiselectHighlight) {
            dispatch(setShowShiftSelectHighlightsAction({
                showShiftSelectHighlights
            }));
        }
    }, [
        dispatch,
        holdingShift,
        hasSelectedItems,
        previousIsShowingMultiselectHighlight
    ]);
    useHotkeys("esc", ()=>{
        const clipIdInUrl = getClipIdFromPath(window.location.pathname);
        //Don't deselect items if using escape key to close an asset
        !clipIdInUrl && dispatch(resetSelectedItemsAction());
        // After resetting selected items, data-focus-visible-added is present on the last selected element
        // We need to clear it manually
        const focused = document.querySelectorAll("[data-focus-visible-added]");
        if (focused && focused.length > 0) {
            // @ts-ignore
            focused[0].blur();
        }
    }, []);
    return null;
};
