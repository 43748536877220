import { useCallback } from "react";
import { useAnalyticsUtilities } from "../hooks/useAnalyticsUtilities";
import META from "./useTrackRenamedClip.meta";
export const useTrackRenamedClip = ()=>{
    const { track } = useAnalyticsUtilities();
    const trackRenamedClip = useCallback((params)=>{
        track(META.name, params);
    }, [
        track
    ]);
    return {
        trackRenamedClip
    };
};
